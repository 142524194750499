import { useEffect, useState } from "react";

// src
import logo from "../src/Images/IsotipoBlanco.png"

// import
import Swal from 'sweetalert2';

// components
import Dashboard from "./Pages/Dashboard/Dashboard";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';


// styles
import "./Styles/Globals.scss"
import "./Styles/Steps.scss"
import "./Styles/Layout.scss"

function App() {

  const [timer, setTimer] = useState(null)
  const [stopTimer, setStopTimer] = useState(false)

  const handleStopTimer = () => {
    if(timer) {
      setStopTimer(true)
      clearInterval(timer)

      Swal.fire({
        title: 'Recorrido detenido',
        icon: 'success',
        showConfirmButton: false,
        showCloseButton: false,
        iconColor: "#ca7f5a",
        timer: 1500
      })
    }
  }
  

  //configuramos idioma para componentes prime react
  useEffect(() => {
    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Limpiar',
      //...
    });    
    locale('es');
  }, [])  
  








  return (<div className="layout">
    <div className="bg-overlay"/>
    
    <button
      onClick={handleStopTimer}
      disabled={stopTimer}
      className={stopTimer ? "StopTimer disabled" : "StopTimer"}
    >
      {
        !stopTimer ?
        <i class="pi pi-spin pi-spinner"/>
        : <i class="pi pi-pause"/>
      }
    </button>
    <header className="Header"><img onClick={()=> window.location.href = "https://www.valoret.com.mx"} src={logo}/></header>
    
      <main className="App">

        <Dashboard timer={timer} setTimer={setTimer} />

      </main>
  </div>);
}

export default App;
