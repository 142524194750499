function formatearNombre(nombreCompleto) {
    const partes = nombreCompleto.split(' ');
    const primerNombre = partes[0];
    const penultimoApellido = partes.length > 2 ? partes[partes.length - 2].substring(0, 4) : '';
    const ultimoApellido = partes.length > 1 ? partes[partes.length - 1].substring(0, 4) : '';
    return `${primerNombre} ${penultimoApellido} ${ultimoApellido}`.trim();
}



export default function ContarPagadosYEntrevistasPorAsesor(registros) {
    // Definir un objeto para almacenar el conteo de registros por asesor
    const conteoPorAsesor = {};

    // Recorrer cada registro
    registros.forEach(registro => {
        const uuidAsesor = registro.uuid_asesor;

        // Si el asesor no está en el conteo, inicializar su conteo
        if (!conteoPorAsesor[uuidAsesor]) {
            conteoPorAsesor[uuidAsesor] = {
                nombre_asesor: registro.nombre_asesor,
                pagados: 0,
                entrevistas: 0
            };
        }


        // Contar los registros de acuerdo a su estatus
        if (registro.estatus === "Pagado") {
            conteoPorAsesor[uuidAsesor].pagados++;
            conteoPorAsesor[uuidAsesor].entrevistas++; // Cuenta también como entrevista
        } else if (registro.estatus === "Solicitud") {
            conteoPorAsesor[uuidAsesor].entrevistas++; // Cuenta también como entrevista
        } else if (registro.estatus === "Entrevista") {
            conteoPorAsesor[uuidAsesor].entrevistas++;
        }

        // Añadir el shortName al registro del asesor
        conteoPorAsesor[uuidAsesor].short_name = formatearNombre(registro.nombre_asesor);
    });

    // Convertir el objeto de conteo a un array de objetos con los datos de cada asesor
    const resultado = Object.keys(conteoPorAsesor).map(uuid_asesor => ({
        name: conteoPorAsesor[uuid_asesor].nombre_asesor,
        short_name: conteoPorAsesor[uuid_asesor].short_name,
        pagados: conteoPorAsesor[uuid_asesor].pagados,
        entrevistas: conteoPorAsesor[uuid_asesor].entrevistas,
        uuid_asesor: uuid_asesor
    }));

    // Ordenar el resultado por el número de registros pagados (de mayor a menor)
    resultado.sort((a, b) => b.pagados - a.pagados);

    // Construir el objeto final con los atributos Asesores, Pagados y Entrevistas
    const objetoFinal = {
        Asesores: resultado.map(asesor => asesor.short_name),
        Pagados: resultado.map(asesor => asesor.pagados),
        Entrevistas: resultado.map(asesor => asesor.entrevistas)
    };


    return objetoFinal;
}
