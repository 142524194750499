import React, { useEffect, useState } from "react"


// import
import Swal from 'sweetalert2';


// imports from mui x charts
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from "@mui/x-charts";
//esto usalo para cambiar de paletas de colores, Lorena quiere una más viva
// import { mangoFusionPalette  } from '@mui/x-charts/colorPalettes';


// Componentes
import AsesoresTable from "../../Components/AsesoresTable/AsesoresTable";
import RegistrosTable from "../../Components/RegistrosTable/RegistrosTable";

// Utils
import filtrarRegistrosPorAsesor from "../../Utils/FiltrarRegistrosPorAsesor";
import ObtenerTrimestreActual from "../../Utils/ObtenerTrimestreActual";
import FiltrarRegistrosPorTrimestre from "../../Utils/FiltrarRegistrosPorTrimestre";
import ContarEstatus from "../../Utils/ContarEstatus";
import ContarPagadosPorSemana from "../../Utils/ContarPagadosPorSemana";
import ContarPagadosYEntrevistasPorAsesor from "../../Utils/ContarPagadosYEntrevistasPorAsesor";



// styles
import "./Dashboard.scss"
import ContarPresupuestosPorSemana from "../../Utils/ContarPresupuestosPorSemana";



export default function({ timer, setTimer }) {
  //Charts Style palette
  const mangoFusionPalette = [
    "#f05c5c",
    "#ff6b39",
    "#fb7777",
    "#a8f43d",
    "#a8f966",
    "#4dff00",
    "#de0f0f",
    "#eb0d23",
    "#f0742c",
    "#bab0ab",
  ];
  
  //Conexión con registros DB
  const SteinStore = require("stein-js-client");
  const APIRegistrosDB = new SteinStore(process.env.REACT_APP_RECORDS_API);
  const APIAsesoresDB = new SteinStore(process.env.REACT_APP_LOGIN_API);


  //Variables para ibases de datos completas y ya filtradas para cada grafica
  const [RegistrosDB, setRegistrosDB] = useState(null)
  const [AsesoresDB, setAsesoresDB] = useState(null)
  const [pagadosYEntrevistasXAsesor, setPagadosYEntrevistasXAsesor] = useState(null)
  const [ConteoDeRegistrosPorSemanaDB, setConteoDeRegistrosPorSemanaDB] = useState(null)
  const [ConteoDeProductosDB, setConteoDeProductosDB] = useState(null)
  // const [ConteoDePorcentajesDB, setConteoDePorcentajesDB] = useState(null)
  const [ConteoDePresupuestos, setConteoDePresupuestos] = useState(null)
  
  
  
  //Estados de funcionamiento para analizar un asesor individualmente
  const [analizarAsesor, setAnalizarAsesor] = useState(null)
  const [analizarAsesorRegistros, setAnalizarAsesorRegistros] = useState([])
  const [analizarAsesorRegistrosPorSemana, setAnalizarAsesorRegistrosPorSemana] = useState([])
  const [analizarAsesorProductos, setAnalizarAsesorProductos] = useState([])
  const [analizarAsesoronteoDePresupuestos, setAnalizarAsesorConteoDePresupuestos] = useState([])
  // const [analizarAsesorPorcentajes, setAnalizarAsesorPorcentajes] = useState([])



  
  //filtro 
  const [fecha_a_medir, setFecha_a_medir] = useState(ObtenerTrimestreActual())
  
  
  // Funcionalidad del dashboard, activamos pestaña de carga
  const [loading, setLoading] = useState(true)
  const [initRender, setInitRender] = useState(1)


  
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedAsesores, setPaginatedAsesores] = useState([]);
  const [paginatedPagados, setPaginatedPagados] = useState([]);
  const [paginatedEntrevistas, setPaginatedEntrevistas] = useState([]);
  const itemsPerPage = 10;





  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };







  useEffect(() => {
    if (pagadosYEntrevistasXAsesor && pagadosYEntrevistasXAsesor.Asesores) {
      const totalItems = pagadosYEntrevistasXAsesor.Asesores.length;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setCurrentPage(1); // Reset to first page when data changes
    }
  }, [pagadosYEntrevistasXAsesor]);

  useEffect(() => {
    if (pagadosYEntrevistasXAsesor && pagadosYEntrevistasXAsesor.Asesores) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedAsesores(pagadosYEntrevistasXAsesor.Asesores.slice(startIndex, endIndex));
      setPaginatedPagados(pagadosYEntrevistasXAsesor.Pagados.slice(startIndex, endIndex));
      setPaginatedEntrevistas(pagadosYEntrevistasXAsesor.Entrevistas.slice(startIndex, endIndex));
    }
  }, [pagadosYEntrevistasXAsesor, currentPage]);
  
  
  
  useEffect(() => {
    const id = setInterval(() => {
      if (currentPage < totalPages) {
        setCurrentPage(prevPage => prevPage + 1);
      } else {
        //si dio click al boton, no vamos a navegar cuando haya terminado
        // if(!timer) {
          window.location.href = 'https://cardex.valoret.com.mx/';
        // }
      }
    }, 15000);

    setTimer(id)
    
    return () => clearInterval(id); // Cleanup interval on component unmount
  }, []);
  
  
  
  
  
  // Actualizaremos la data de las graficas casda que cambiemos la fecha a medir
  useEffect(() => {
    setInitRender(2)
  }, [fecha_a_medir])






  //if it is the fitrst render, now get the api for DBs
  useEffect(() => {
    
    if(initRender === 2) {
      setInitRender(3)

      APIRegistrosDB
          .read("Citas-CRM-Valoret", {authentication: {
            username: process.env.REACT_APP_RECORDS_USERNAME,
            password: process.env.REACT_APP_RECORDS_PASSWORD
          }})
          .then(data => {
            let registrosDB = data;
            let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(registrosDB, fecha_a_medir);
            
            
            
            
            // // Raw data
            setRegistrosDB(registrosDelTrimestre)

            // //total de registros por asesor
            setPagadosYEntrevistasXAsesor(ContarPagadosYEntrevistasPorAsesor(registrosDelTrimestre))

            // //total de registros como dirección
            setConteoDeRegistrosPorSemanaDB(ContarPagadosPorSemana(registrosDelTrimestre, fecha_a_medir))
            
            // //total de registros por productos 
            setConteoDeProductosDB(ContarEstatus(registrosDelTrimestre))

            // //total de registros por productos 
            setConteoDePresupuestos(ContarPresupuestosPorSemana(registrosDelTrimestre, fecha_a_medir))
            

            APIAsesoresDB
              .read("Asesores-CRM-Valoret", {authentication: {
                username: process.env.REACT_APP_LOGIN_API_USERNAME,
                password: process.env.REACT_APP_LOGIN_API_PASSWORD
              }})
              .then(data => {
                let asesoresDB = data;
                
                setAsesoresDB(asesoresDB)
                setLoading(false)
              })
              .catch(e => {
                console.error(e);
              });
  
  
  
          })
  
          .catch(e => {
            console.error(e);
          });
    }
    
  }, [initRender])








  // Cargando al ingresasr al dashboard, cuando carga las apis ya no
  useEffect(() => {
    if(loading) {
      Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.close()
      // Obtener el elemento div por su ID
      const miDiv = document.getElementById('dashboard');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])




  // Busca registros por Asesor
  useEffect(() => {
    if(analizarAsesor) {
      // Obtener el elemento div por su ID
      const miDiv = document?.getElementById('searcher');
  
      // Hacer que la página se desplace automáticamente hacia el div
      miDiv?.scrollIntoView({ behavior: 'smooth' });
      
  
      const DBfiltrada = filtrarRegistrosPorAsesor(RegistrosDB, analizarAsesor.uuid);

      
      setAnalizarAsesorRegistros(DBfiltrada)
      setAnalizarAsesorRegistrosPorSemana(ContarPagadosPorSemana(DBfiltrada, fecha_a_medir))
      setAnalizarAsesorProductos(ContarEstatus(DBfiltrada))
      setAnalizarAsesorConteoDePresupuestos(ContarPresupuestosPorSemana(DBfiltrada, fecha_a_medir))
    }

    
  }, [analizarAsesor])










  return(<React.Fragment>
    <div id="dashboard" style={{height: "40px"}} />

    {
      !loading && 
      <section className="step-wrap-container-dashboard">
        <div className="step-container">
          <div style={{height: "20px"}} />

          <h1 className="h1" style={{textAlign: "center"}}>
            Por Asesor - Trimestre Actual
          </h1>

          <div style={{height: "25px"}} />

          <BarChart
            series={[
              { data: paginatedPagados, color: "#a8f966", label: "Pagados" },
              { data: paginatedEntrevistas, color: "#3bf867", label: "Entrevistas" },
            ]}

            height={650}
            layout="horizontal"
            yAxis={[{ 
              data: paginatedAsesores, 
              scaleType: 'band', 
            }]}
            margin={{left: 160, top: 50, bottom: 50, right: 10}}
            colors={mangoFusionPalette}
          />

          <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span style={{ margin: '0 10px' }}>Página {currentPage} de {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Siguiente
            </button>
          </div>

        </div>
      </section>
    }




    <div style={{height: "40px"}} />




    <section className="step-wrap-container step-wrap-container-dashboard">
      
      <div className="step-container">

        <div style={{height: "40px"}} />

        <h1 className="h1" style={{textAlign: "center"}}>
          Dashboard Valoret
        </h1>

        <div style={{height: "25px"}} />


        {
          (
            loading
          ) ?
          <div className="dashboard-container-skeleton" style={{height: "664px", display: "grid", placeItems: "center"}}>
            <h2 className="h2">Cargando Información...</h2>
          </div>
          
          :
          <div className="dashboard-container">
            <div className="chart1">
              <h3 className="h3">Por Asesor - Trimestre Actual</h3>

              <BarChart
                // dataset={pagadosYEntrevistasXAsesor}
                series={[
                  { data: paginatedPagados.length > 5 ? paginatedPagados.slice(0, 5) : paginatedPagados , color: "#a8f966", label: "Pagados" },
                  { data: paginatedEntrevistas.length > 5 ? paginatedEntrevistas.slice(0, 5) : paginatedEntrevistas, color: "#3bf867", label: "Entrevistas" },
                ]}

                height={300}
                layout="horizontal"
                yAxis={[{ 
                  data: paginatedAsesores.length > 5 ? paginatedAsesores.slice(0, 5) : paginatedAsesores, 
                  scaleType: 'band', 
                }]}
                margin={{left: 200, top: 50, bottom: 50, right: 10}}
                colors={mangoFusionPalette}
              />
            </div>












            <div className="chart2">
              <h3 className="h3">Pagados - Trimeste Actual</h3>
              <div className="lineChartContainer">
                <LineChart
                  colors={mangoFusionPalette}
                  grid={{ horizontal: true}}
                  dataset={ConteoDeRegistrosPorSemanaDB}


                  // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                  xAxis={[{
                    dataKey: "n_semana",
                    valueFormatter: (value => ConteoDeRegistrosPorSemanaDB[value-1].semana)
                  }]}
                  series={[
                    {
                      dataKey: "total_pagados",
                      area: true,
                      color: "#f05c5c"
                    },
                  ]}




                  width={600}
                  height={300}
                />
              </div>
            </div>
            
            
            
            
            
            
            
            

            <div className="chart3">
              <h3 className="h3">Estatus - Trimestre Actual</h3>
              <PieChart
                colors={mangoFusionPalette}



                series={[
                  {
                    data: ConteoDeProductosDB,
                    innerRadius: 30,
                    outerRadius: 120,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  },
                ]}



                width={600}
                height={300}
              />
            </div>

            
            
            
            
            
            
            
            

            <div className="chart4">
              <h3 className="h3">Presupuestos Pagados - Trimestre Actual</h3>
              <div className="lineChartContainer">
                <LineChart
                  colors={mangoFusionPalette}
                  grid={{ horizontal: true}}
                  dataset={ConteoDePresupuestos}


                  // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                  xAxis={[{
                    dataKey: "n_semana",
                    valueFormatter: (value => ConteoDePresupuestos[value-1].semana)
                  }]}
                  series={[
                    {
                      dataKey: "total_presupuesto",
                      area: true,
                      color: "#f05c5c"
                    },
                  ]}



                  width={600}
                  height={300}
                  // margin={{left: 70}}
                />
              </div>
            </div>
          </div>
        }































      </div>
    </section>
    {
      !loading &&
      <div className="step-wrap-container-searcher">
        <h1 className="h1">Analisis individual</h1>
        <AsesoresTable data={AsesoresDB} onAsesorClick={setAnalizarAsesor} />
      </div>
    }



    { 
      analizarAsesor &&
      <> 
        <div id="searcher" style={{height: "40px"}} />
      
        <section className="step-wrap-container step-wrap-container-dashboard">
        
          <div className="step-container">

            <div style={{height: "40px"}} />

            <h1 className="h1" style={{textAlign: "center"}}>
              Desempeño del asesor
            </h1>

            <div style={{height: "25px"}} />














            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Registros - Trimestre Actual</h3>
                <div style={{height: "20px"}} />
                <RegistrosTable data={analizarAsesorRegistros} small />
              </div>












              <div className="chart2">
                <h3 className="h3">Pagados - Trimestre Actual</h3>
                <div className="lineChartContainer">
                  <LineChart
                    colors={mangoFusionPalette}

                    grid={{ horizontal: true}}
                    dataset={analizarAsesorRegistrosPorSemana}



                    // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                    xAxis={[{
                      dataKey: "n_semana",
                        valueFormatter: (value => ConteoDeRegistrosPorSemanaDB[value-1].semana)
                    }]}
                    series={[
                      {
                        dataKey: "total_pagados",
                        area: true,
                        color: "#f05c5c"
                      },
                    ]}





                    width={600}
                    height={300}
                  />
                </div>
              </div>
              
              
              
              
              
              
              
              

              <div className="chart3">
                <h3 className="h3">Estatus - Trimestre Actual</h3>
                <PieChart
                  colors={mangoFusionPalette}



                  series={[
                    {
                      data: analizarAsesorProductos,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}



                  
                  width={600}
                  height={300}
                />
              </div>

              
              
              
              
              
              
              
              

              <div className="chart4">
                <h3 className="h3">Presupuestos Pagados - Trimestre Actual</h3>
                <div className="lineChartContainer">
                  <LineChart
                    colors={mangoFusionPalette}
                    grid={{ horizontal: true}}
                    dataset={analizarAsesoronteoDePresupuestos}


                    // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                    xAxis={[{
                      dataKey: "n_semana",
                      valueFormatter: (value => analizarAsesoronteoDePresupuestos[value-1].semana)
                    }]}
                    series={[
                      {
                        dataKey: "total_presupuesto",
                        area: true,
                        color: "#f05c5c"
                      },
                    ]}



                    width={600}
                    height={300}
                    // margin={{left: 70}}
                  />
                </div>
              </div>
            </div>






























          </div>
        </section>
      </>
    }
  </React.Fragment>
  )
}