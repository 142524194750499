import ObtenerSemanasDelTrimestre from "./ObtenerSemanasDelTrimestre";

// Objeto para mapear los nombres de los meses abreviados en español a sus equivalentes en inglés
const mesesAbreviadosEnIngles = {
  "ENE": "Jan",
  "FEB": "Feb",
  "MAR": "Mar",
  "ABR": "Apr",
  "MAY": "May",
  "JUN": "Jun",
  "JUL": "Jul",
  "AGO": "Aug",
  "SEP": "Sep",
  "OCT": "Oct",
  "NOV": "Nov",
  "DIC": "Dec"
};

// Función para obtener la fecha de inicio de una semana específica dentro de un año
function obtenerFechaInicioSemana(semana, year) {
  const mesEnIngles = mesesAbreviadosEnIngles[semana.slice(0, 3)]; // Obtener el nombre del mes en inglés
  const numeroSemana = parseInt(semana.slice(3)); // Obtener el número de semana
  const primerDiaMes = new Date(`${mesEnIngles} 1, ${year}`); // Crear una fecha para el primer día del mes
  const primerDiaSemana = new Date(primerDiaMes); // Crear una copia de la fecha del primer día del mes
  primerDiaSemana.setDate(primerDiaSemana.getDate() + 7 * (numeroSemana - 1)); // Obtener el primer día de la semana
  return primerDiaSemana; // Devolver la fecha de inicio de la semana
}

// Función para obtener la fecha de fin de una semana específica dentro de un año
function obtenerFechaFinSemana(semana, year) {
  const inicioSemana = obtenerFechaInicioSemana(semana, year); // Obtener la fecha de inicio de la semana
  const finMes = new Date(year, inicioSemana.getMonth() + 1, 0); // Obtener el último día del mes actual
  const finSemana = new Date(inicioSemana); // Crear una copia de la fecha de inicio de la semana
  finSemana.setDate(finSemana.getDate() + 6); // Agregar 6 días para obtener un punto de referencia para el final de la semana

  // Verificar si el último día del mes cae dentro de la semana actual
  if (finMes <= finSemana) {
    return finMes; // Si es así, establecer el último día del mes como el final de la semana
  } else {
    return finSemana; // De lo contrario, establecer el punto de referencia como el final de la semana
  }
}


export default function ContarPagadosPorSemana(registros, trimestre) {
  const trimestreParts = trimestre.split("Q");
  const trimestreNum = parseInt(trimestreParts[0]);
  const year = parseInt(trimestreParts[1]);
  const conteoPorSemana = {};
  const semanasDelTrimestre = ObtenerSemanasDelTrimestre(trimestre);

  semanasDelTrimestre.forEach((semana) => {
    const inicioSemana = obtenerFechaInicioSemana(semana, year);
    const finSemana = obtenerFechaFinSemana(semana, year);

    const pagadosEnSemana = registros.filter((registro) => {
      const fechaRegistro = new Date(
        registro.fecha.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
      );
      return fechaRegistro >= inicioSemana && fechaRegistro <= finSemana && registro.estatus === "Pagado";
    });

    conteoPorSemana[semana] = pagadosEnSemana.length;
  });

  const resultadoFinal = Object.keys(conteoPorSemana).map((semana, idx) => {
    return { 
      n_semana: idx + 1, 
      semana: semana, 
      total_pagados: conteoPorSemana[semana] 
    };
  });

  return resultadoFinal;
}