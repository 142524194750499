import React from 'react';

//utils
import convertirADinero from '../../Utils/convertirADinero';
import extraerPrimerNombre from '../../Utils/extraerPrimerNobre';
import ObtenerNombreYApellido from '../../Utils/ObtenerNombreYApellido';

//styles
import "./RegistrosTable.scss"

const RegistrosTable = ({ data, small=false }) => {
  return (
    <div className={`registros-table-container ${small ? "small" : ""}`}>
      <table className="registros-table">
        <thead>
          <tr>
            <th>Asesor</th>
            <th>Fecha</th>
            <th>Estatus</th>
            <th>Enfoque</th>
            <th>Producto</th>
            <th>Presupuesto</th>
            <th>Porcentaje</th>
          </tr>
        </thead>
        <tbody>
          {data.map((registro, index) => (
            <tr key={index}>
              <td>{registro?.nombre_asesor ? extraerPrimerNombre(registro?.nombre_asesor) : " "}</td>
              <td>{registro.fecha}</td>
              <td>{registro.estatus}</td>
              <td>{registro.enfoque}</td>
              <td>{registro.producto}</td>
              <td>{registro?.presupuesto ? convertirADinero(registro?.presupuesto) : " "}</td>
              <td>{registro.porcentaje_de_cierre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrosTable;
